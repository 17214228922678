import { useInfiniteQuery, useQuery } from 'react-query';

import { API } from './Api';
import { TirnuClient } from './Axios';

const fetchTransaction = (transactionLedgerDetail) => () => {
  return TirnuClient.post(API.v1.transaction.fetch, transactionLedgerDetail);
};

const useTransactionQuery = (transactionLedgerDetail) => {
  return useQuery(
    [API.v1.transaction.fetch, JSON.stringify(transactionLedgerDetail)],
    fetchTransaction(transactionLedgerDetail),
    {
      keepPreviousData: true,
      enabled: !!transactionLedgerDetail?.ledgerId,
      placeholderData: [],
      select: ({ data }) => data || [],
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const fetchInfiniteTransaction =
  (transactionLedgerDetail) =>
  ({ pageParam = 1 }) => {
    return TirnuClient.post(API.v1.transaction.fetch, {
      ...transactionLedgerDetail,
      page: pageParam,
    });
  };

const useInfiniteTransactionQuery = (transactionLedgerDetail) => {
  return useInfiniteQuery(
    transactionLedgerDetail
      ? [API.v1.transaction.fetch, JSON.stringify(transactionLedgerDetail)]
      : [],
    transactionLedgerDetail ? fetchInfiniteTransaction(transactionLedgerDetail) : () => {},
    {
      enabled: !!transactionLedgerDetail,
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
      notifyOnChangeProps: 'tracked',
      select: (resultSet) => {
        const lastResult = resultSet.pages[resultSet.pages.length - 1];
        try {
          const requestConfigs = JSON.parse(lastResult.config.data);
          if (lastResult.data?.hasNextPage) {
            lastResult.nextCursor = requestConfigs.page + 1;
          }
        } catch (error) {
          lastResult.nextCursor = undefined;
        }
        return resultSet;
      },
      onSuccess: (data) => data.data,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const fetchTransactionReport =
  ({ ledgerId, fromDate, toDate }) =>
  () => {
    return TirnuClient.post(API.v1.transaction.report, {
      ledgerId,
      fromDate,
      toDate,
    });
  };

const useFetchTransactionReport = ({ ledgerId, fromDate, toDate }) => {
  return useQuery(
    API.v1.transaction.report,
    fetchTransactionReport({ ledgerId, fromDate, toDate }),
    {
      enabled: !!ledgerId && !!fromDate && !!toDate,
      select: ({ data }) => data.data,
    }
  );
};

const useTransactionSummary = (startDate, endDate) => {
  return useQuery(
    ['transactionSummary', startDate, endDate],
    () => {
      return TirnuClient.post(API.v1.transactionSummary.summary, { startDate, endDate });
    },
    {
      select: ({ data }) => ({
        filteredTransactions: data.data.filteredTransactions,
        totalBalance: data.data.totalBalance,
      }),
      enabled: !!startDate && !!endDate,
    }
  );
};

const useAdminEarning = (startDate, endDate) => {
  return useQuery(
    ['adminEarning', startDate, endDate],
    () => {
      return TirnuClient.post(API.v1.adminEarning.earning, { startDate, endDate });
    },
    {
      select: ({ data }) => data, 
      enabled: !!startDate && !!endDate, 
    }
  );
};

const useLatestEarning = () => {
  return useQuery(
    'latestEarning',
    () => {
      return TirnuClient.get(API.v1.latestEarning.earning);
    },
    {
      select: ({ data }) => data.latestEarnings
      ,
    }
  );
};

const useIbanForAmount = () => {
  return useQuery(
    'ibanAmount',
    () => {
      return TirnuClient.get(API.v1.ibanAmount.iban);
    },
    {
      select: ({ data }) => data.iban
      ,
    }
  );
};

const useAdminTransaction = (startDate, endDate, iban, limit, page, status, type) => {
  return useQuery(
    ['adminTransactionList', startDate, endDate,iban, limit, page, status, type ],
    () => {
      return TirnuClient.post(API.v1.transaction.adminTransaction, { startDate, endDate, iban, limit, page, status, type });
    },
    {
      select: ({ data }) => data.data, 
      enabled: !!startDate && !!endDate, 
    }
  );
};



export {
  useTransactionQuery,
  useInfiniteTransactionQuery,
  useFetchTransactionReport,
  useTransactionSummary,
  useAdminEarning,
  useLatestEarning,
  useIbanForAmount,
  useAdminTransaction
};
