import { formatCurrency } from 'assets/utils';

import React, { Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styled from 'styled-components';

import accountList from '../Shared/account-list';

const AssociationText = styled.span`
  opacity: 0.6;
`;

const ActiveIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--primary);
`;

const LedgerItem = styled.a`
  &:hover,
  &:focus {
    ${ActiveIcon} {
      background-color: var(--white);
    }
  }
`;

function AdminListSidebar({ Iban }) {
  return (
    <Fragment>
      {Iban &&
        Iban.length > 0 &&
        Iban?.map((ledger) => {
          const accountType =
            accountList.find(
              (acc) => acc.asset_type === ledger.asset_type?.toLowerCase()
            ) || {};
          return (
            <li key={ledger?.ledger_id} className="mb-1 ml-3 hide-collapse">
              <LedgerItem
                className="d-flex align-items-center cursor-pointer sidebar-account"
                title={ledger.account_name}
              >
                <div
                  className="flag-img mr-2"
                  style={{
                    backgroundImage: `url(${accountType.flag})`,
                  }}
                />
                <div className="d-flex flex-column flex-grow-1 w-50">
                  <span className="fs-14">
                    {formatCurrency(ledger.amount, ledger.asset_type)}
                  </span>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{ledger.account_name}</Tooltip>}
                  >
                    {({ ref, ...triggerHandler }) => (
                      <AssociationText
                        ref={ref}
                        className="fs-12 text-truncate nav-text"
                        {...triggerHandler}
                      >
                        {ledger.account_name}
                      </AssociationText>
                    )}
                  </OverlayTrigger>
                </div>

                {ledger.active && (
                  <ActiveIcon className="active-icon" title="Active Account" />
                )}
              </LedgerItem>
            </li>
          );
        })}
    </Fragment>
  );
}

export default AdminListSidebar;
