import { useMutation, useQuery } from 'react-query';

import { API } from './Api';
import { TirnuClient } from './Axios';
import swal from 'sweetalert';

const initiateKYC = async () => {
  const { data: tokenData } = await TirnuClient.get(API.v1.user.initiateKYC);
  return tokenData;
};

const useInitiateKYC = () => {
  return useMutation(API.v1.user.initiateKYC, initiateKYC, {
    onError: (error) => {
      console.log(error);
    },
  });
};

const fetchKYCStatus = async () => {
  const { data: kycStatus } = await TirnuClient.get(API.v1.user.fetchKYCStatus);
  return kycStatus;
};

const useFetchKYCStatus = () => {
  return useQuery(API.v1.user.fetchKYCStatus, fetchKYCStatus, {
    select: (res) => res.data,
    onError: (error) => {
      console.log(error);
    },
  });
};

const setKYCVerified = async () => {
  return TirnuClient.put(API.v1.user.setKYCVerified);
};

const useSetKYCVerified = () => {
  return useMutation(setKYCVerified, {
    onError: (error) => {
      console.log(error);
    },
  });
};

const uploadUserImage = async (imageFile) => {
  const { data: user } = await TirnuClient.put(
    API.v1.user.uploadUserImage,
    imageFile
  );
  return user;
};

const useUploadUserImage = () => {
  return useMutation(API.v1.user.uploadUserImage, uploadUserImage, {
    onError: (error) => {
      console.log(error);
    },
  });
};

const fetchUserDetail = () => {
  return TirnuClient.get(API.v1.user.fetchDetail);
};

const useUserDetailQuery = (queryEnabled = false) => {
  return useQuery(API.v1.user.fetchDetail, fetchUserDetail, {
    enabled: queryEnabled,
    select: (response) => response.data || null,
    onError: (error) => {
      console.log(error);
    },
  });
};

const useFetchCountries = () => {
  return useQuery(
    API.v1.user.countries,
    () => TirnuClient.get(API.v1.user.countries),
    {
      enabled: true,
      select: (response) => response.data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const useFetchRiskLevel = () => {
  return useQuery(
    API.v1.user.riskLevel,
    () => TirnuClient.get(API.v1.user.riskLevel),
    {
      enabled: true,
      select: (response) => response.data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};
const useFetchCompanyLegalForm = () => {
  return useQuery(
    API.v1.user.companyLegalForm,
    () => TirnuClient.get(API.v1.user.companyLegalForm),
    {
      enabled: true,
      select: (response) => response.data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};
const useFetchCompanySize = () => {
  return useQuery(
    API.v1.user.companySize,
    () => TirnuClient.get(API.v1.user.companySize),
    {
      enabled: true,
      select: (response) => response.data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const useValidateEmail = () => {
  return useMutation((email) => {
    return TirnuClient.get(API.v1.user.validateEmail, { params: { email } });
  });
};

const useRegisterBusiness = () => {
  return useMutation(({ businessDetails }) => {
    return TirnuClient.post(API.v1.user.registerCompany, businessDetails);
  });
};

const useUpdateFees = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.fees.createFeeRate, data);
  });
}

const useBulkUpdateFees = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.fees.updatebulkfees, data);
  });
}

const usesendtopupemail = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.fees.sendtopupemail, data);
  });
}

const changeUserActiveness = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.user.changeuseractiveness, data);
  });
}

const updatebusinessfromadmin = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.user.updatebusinessfromadmin, data);
  });
}

const updatepersonalfromadmin = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.user.updatepersonalfromadmin, data);
  });
}

const updatetransactionlimit = () => {
  return useMutation((data) => {
    return TirnuClient.post(API.v1.user.updatetransactionlimit, data);
  });
}

const userCount = (selectedType) => {
  return useQuery(['userCount', selectedType], () => 
    TirnuClient.get(API.v1.user.count, {
      params: { userType: selectedType },
    }).then((response) => response.data) 
  );
};

export {
  useInitiateKYC,
  useUserDetailQuery,
  useFetchKYCStatus,
  useUploadUserImage,
  useSetKYCVerified,
  useFetchCountries,
  useFetchRiskLevel,
  useFetchCompanyLegalForm,
  useFetchCompanySize,
  useRegisterBusiness,
  useValidateEmail,
  fetchUserDetail,
  useUpdateFees,
  updatebusinessfromadmin,
  updatepersonalfromadmin,
  updatetransactionlimit,
  useBulkUpdateFees,
  usesendtopupemail,
  userCount,
  changeUserActiveness
};
