import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../../context/ThemeContext';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Box, Button, useTheme, TableCell } from '@mui/material';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import ShowDocuments from './showdocument';
import PaymentUpload from './payment_invoice';
import TableWrapperComponent from 'jsx/components/BasicTable/BasicTable';
import { TableRowInvestment } from 'jsx/components/BasicTable/styles';
import UpdateUser from './UpdateUser';
import UpdateFee from './UserFees';
import UpdateTransactionLimit from './updatetransactionlimit';
import { useFetchCountries } from 'services/User';

const UserDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { row } = location.state || {};
  const [documents, setDocuments] = useState({
    city: '',
    taxpayer_identification_number: '',
    number_of_directors: 0,
    documents: [],
    fees: {
      primaryFee: 0,
      primaryFeeType: '',
      secondaryFee: 0,
      secondaryFeeType: '',
      monthlyFee: 0,
      monthlyFeeType: '',
    },
  });
  const [view, setView] = useState('');
  const { state } = useContext(ThemeContext);
  const theme = useTheme();
  const hasIbanAndAmount = row?.ledgers?.some(
    (ledger) => ledger?.iban || ledger?.amount >= 0
  );
  const [selectedLedger, setSelectedLedger] = useState(null);

  const headCellsTransactions = [
    {
      sortName: 'accountName',
      label: 'Account Name',
    },
    {
      sortName: 'iban',
      label: 'Iban',
    },
    {
      sortName: 'amount',
      label: 'Amount',
    },

    {
      sortName: 'update',
      label: 'Update',
    },
  ];

  const { data: countries } = useFetchCountries();

  const [ISOCountryCodes, setISOCountryCodes] = useState([]);

  useEffect(() => {
    setISOCountryCodes(
      countries?.result?.map((code) => ({
        label: code.name,
        value: code.id,
      }))
    );
  }, [countries]);
  useEffect(() => {
    {
      row?.accountType === 'business' &&
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/business/documents?id=${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${state.auth.accessToken}`,
                'x-refresh': `${state.auth.refreshToken}`,
              },
            }
          )
          .then((res) => {

            setDocuments({
              company_details: res.data.company_details,
              number_of_directors: res.data.number_of_directors,
              documents: res.data.company_details?.documents,
              fees: res.data?.fees,
              ledgers: res.data?.ledgers,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }
    {
      row?.accountType === 'personal' &&
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/personal/documents?id=${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${state.auth.accessToken}`,
                'x-refresh': `${state.auth.refreshToken}`,
              },
            }
          )
          .then((res) => {
            
            setDocuments({
              personal_details: res?.data?.personalDetails,
              documents: res?.data?.personalDetails?.documents,
              fees: res?.data?.fees,
              ledgers: res?.data?.ledgers,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }, []);

  const Alldataavailable = (value) => {
    if (row.accountType === 'personal' && !documents?.documents) {
      history.push(`/personal-form/${row._id}`);
    } else if (
      documents.number_of_directors == 0 &&
      row?.accountType !== 'personal'
    ) {
      history.push(`/directors/${row._id}`);
    } else if (documents.number_of_directors > 0 && !documents?.documents) {
      history.push(`/documents/${row._id}`);
    }
    setView(value);
  };

  const handleUpdateClick = (ledger) => {
    setSelectedLedger(ledger);
    setView('updateAccount');
  };

  const formatFee = (fee, feeType) => {
    if (feeType === 'percentage') {
      return `${fee}%`;
    } else if (feeType === 'absolute') {
      return `€${fee}`;
    }
    return 'N/A';
  };

  return (
    <div className="d-flex flex-column text-capitalize">
      {view === '' && (
        <>
          {' '}
          <hr
            style={{
              border: '1px solid #d0d4e2',
              width: '100%',
              marginTop: '-1.9rem',
              marginBottom: '1rem',
            }}
          />
          <ResponsiveContainer>
            <div className="d-flex justify-content-between  w-100 align-items-center mb-4">
              <div className="d-flex gap-5 justify-content-center align-items-center mr-5">
                <div className="d-flex gap-5 justify-content-start align-items-start mr-5 flex-column">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      Full Name:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {row?.full_name?.first_name} {row?.full_name?.last_name}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {row?.accountType === 'business' ? (
                      <>
                        <p className="m-0" style={{ minWidth: '130px' }}>
                          {' '}
                          Tax Id No:
                        </p>
                        <p
                          style={{
                            margin: '0px',
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: '500',
                          }}
                        >
                          {
                            documents?.company_details
                              ?.taxpayer_identification_number
                          }
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="m-0" style={{ minWidth: '130px' }}>
                          Date of birth:
                        </p>
                        <p
                          style={{
                            margin: '0px',
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: '500',
                          }}
                        >
                          {documents?.personal_details?.date_of_birth}
                        </p>
                      </>
                    )}
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      City:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {row?.accountType === 'business'
                        ? documents?.company_details?.city
                        : documents?.personal_details?.address?.address_city}
                    </p>
                  </div>
                  {documents?.company_details?.country_id && (
                    <>
                      {' '}
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{ gap: '10px' }}
                      >
                        {' '}
                        <p className="m-0" style={{ minWidth: '130px' }}>
                          Country:
                        </p>{' '}
                        <p
                          style={{
                            margin: '0px',
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: '500',
                          }}
                        >
                          {
                            ISOCountryCodes?.find(
                              (country) =>
                                country.value ==
                                documents?.company_details?.country_id
                            )?.label
                          }
                        </p>
                      </div>
                    </>
                  )}
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      Phone number:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {row?.phone_number}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      Status:
                    </p>{' '}
                    <div
                      className="d-flex justify-content-center align-items-center px-2 py-1"
                      style={{
                        backgroundColor:
                          row?.status === 'approved' ? 'green' : '#e1df10',
                        borderRadius: '10px',
                      }}
                    >
                      <p
                        style={{
                          color: 'var(--white)',
                          margin: '0px',
                          fontSize: '13px',
                        }}
                      >
                        {row?.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between  w-100 align-items-center mb-4">
              <div className="d-flex gap-5 justify-content-center align-items-center mr-5">
                <div className="d-flex gap-5 justify-content-start align-items-start mr-5 flex-column">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      PrimaryFee:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {formatFee(
                        documents?.fees?.primaryFee,
                        documents?.fees?.primaryFeeType
                      )}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      {' '}
                      SecondaryFee:
                    </p>
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {formatFee(
                        documents?.fees?.secondaryFee,
                        documents?.fees?.secondaryFeeType
                      )}
                    </p>
                  </div>

                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ gap: '10px' }}
                  >
                    {' '}
                    <p className="m-0" style={{ minWidth: '130px' }}>
                      MonthlyFee:
                    </p>{' '}
                    <p
                      style={{
                        margin: '0px',
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: '500',
                      }}
                    >
                      {formatFee(
                        documents?.fees?.monthlyFee,
                        documents?.fees?.monthlyFeeType
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ResponsiveContainer>
          <div
            className="d-flex flex-column flex-lg-row justify-content-between align-items-start"
            style={{ gap: '10px' }}
          >
            <div
              className="px-4 pt-3 w-100 "
              style={{
                minHeight: '200px',
                border: '1px solid #d0d4e2',
                borderRadius: '3px',
                overflow: 'hidden',
              }}
            >
              <h3>Account Information</h3>
              <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  <p className="m-0">Email</p>
                  <p
                    style={{
                      margin: '0px',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {row?.email}
                  </p>
                </div>
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  {row?.accountType === 'business' ? (
                    <>
                      <p className="m-0">Company Name:</p>
                      <p
                        style={{
                          margin: '0px',
                          color: 'black',
                          fontSize: '15px',
                          fontWeight: '500',
                        }}
                      >
                        {row?.company_details?.name}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="m-0">National ID:</p>
                      <p
                        style={{
                          margin: '0px',
                          color: 'black',
                          fontSize: '15px',
                          fontWeight: '500',
                        }}
                      >
                        {documents?.personal_details?.national_id}
                      </p>
                    </>
                  )}
                </div>
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  <p className="m-0">Registered at:</p>
                  <p
                    style={{
                      margin: '0px',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {new Date(row?.createdAt).toISOString().split('T')[0]}
                  </p>
                </div>
                <div className="mb-3" style={{ minWidth: '50%' }}>
                  <p className="m-0">KYC Status:</p>
                  <p
                    style={{
                      margin: '0px',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                  >
                    {row?.kyc?.status}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            {/* <Button
              variant="contained"
              type="submit"
              size="small"
              sx={{ maxWidth: 150 }}
              onClick={() => Alldataavailable('editProfile')}
            >
              Edit Profile
            </Button> */}
            <Button
              variant="contained"
              size="small"
              sx={{ maxWidth: 150 }}
              onClick={() => Alldataavailable('businessDocument')}
            >
              View Document
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              sx={{ maxWidth: 150 }}
              onClick={() => Alldataavailable('updateLimit')}
            >
              View Account
            </Button>
          </div>
          {documents?.documents && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 4,
              }}
            >
              <PaymentUpload id={row._id} document={documents.documents} />
              <UpdateFee id={row._id} fees={documents.fees} />
            </Box>
          )}
        </>
      )}
      {view === 'businessDocument' && (
        <ShowDocuments
          documents={documents}
          setDocuments={setDocuments}
          row={row}
          Alldataavailable={Alldataavailable}
        />
      )}
      {/* {view === 'editProfile' && (
        <UpdateUser
          documents={documents}
          row={row}
          theme={theme}
          Alldataavailable={Alldataavailable}
        />
      )} */}

      {view === 'updateLimit' && (
        <div
          className="w-100"
          style={{
            minHeight: '200px',
            borderRadius: '3px',
          }}
        >
          <h3 className="mb-2">Ledger Information</h3>
          {documents?.ledgers?.length > 0 ? (
            <TableWrapperComponent headerCells={headCellsTransactions}>
              {documents?.ledgers.map((ledger, idx) => (
                <TableRowInvestment
                  key={idx}
                  sx={{
                    padding: '15px',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    backgroundColor: idx % 2 !== 0 ? '#f9f9f9' : '',
                  }}
                  className="text-capitalize"
                >
                  <TableCell sx={tableCellStyle}>
                    {ledger?.ledger_meta?.ledger?.account_name || 'N/A'}
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    {ledger?.iban || 'N/A'}
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    {ledger?.amount >= 0 ? ledger.amount : 'N/A'}
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <Button
                      style={{
                        color: '#fff',
                        fontSize: '14px',
                        background: 'rgb(0, 145, 147)',
                        lineHeight: '1.2',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 20px',
                      }}
                      onClick={() => handleUpdateClick(ledger)}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRowInvestment>
              ))}
            </TableWrapperComponent>
          ) : (
            <div
              className="text-center d-flex justify-content-center mt-3 align-items-center"
              style={{
                minHeight: '500px',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
            >
              <h1>No Account</h1>
            </div>
          )}
          <Button
            variant="contained"
            size="small"
            className="mt-3"
            sx={{ maxWidth: 150 }}
            onClick={() => Alldataavailable('')}
          >
            Prev
          </Button>
        </div>
      )}

      {view === 'updateAccount' && selectedLedger && (
        <UpdateTransactionLimit
          documents={documents}
          selectedLedger={selectedLedger}
          Alldataavailable={Alldataavailable}
        />
      )}
    </div>
  );
};

const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 0;

  @media (min-width: 768px) {
  }
`;

const tableCellStyle = {
  minWidth: '100px',

  overflow: 'hidden',
  color: '#8B9DB8',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const ImagePlaceholder = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  text-shadow: -1px -1px 0 #8b9db8, 1px -1px 0 #8b9db8, -1px 1px 0 #8b9db8,
    1px 1px 0 #8b9db8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: 5px solid var(--primary);
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  font-weight: bold;
  color: var(--white);
  margin-right: 5px;

  @media (max-width: 550px) {
    width: 70px;
    font-size: 34px;
    height: 70px;
  }
`;

export default UserDetails;
